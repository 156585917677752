// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import 'zone.js/dist/zone-error'
export const environment = {
  production: false, 
 //baseUrl: 'http://webapi.pr.qa.rpost.net/',
 //baseUrl: 'http://webapi.qa.rpost.net/',
 baseUrl: 'https://webapi.r1.rpost.net/',
 //baseUrl: 'https://redact.pr.qa.rpost.net/',
 //baseUrl:'https://webapi.s1.rpost.info/',
 ActAPIbaseUrl:'https://activitytrackerapi.r1.rpost.net/',
 apiKey: '2FuZ3VsaUByc4AzdC5jb20iLCJTZXNzaW9uSWQiOiJmMzc0MjVl'
 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
